import React, { useEffect, useState } from 'react'
import { PrimaryButton } from '../../Components/Button/Button'
import { TextInput } from '../../Components/Input/Input';
import Cookies from 'universal-cookie';
import { useHistory } from "react-router";
import axios from 'axios';
import { BASE_URL } from '../../Constants/Constants';
import logo from "../../Images/logo.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, resendOtpAction } from '../../Redux/Actions/LoginAction';
import OtpVerification from '../Register/RegisterStep/OtpVerification';
import OtpInput from '../../Components/OtpInput/OtpInput';
import { otpVerificationAction } from '../../Redux/Actions/RegisterAction';

const cookies = new Cookies();

const ForgotPassword = () => {
    const [userName, setUserName] = useState("");
    const { isSent, sentLoading } = useSelector(state => ({...state.resendOtpReducer}));
    const { loading, isDone, otpData } = useSelector(state => ({...state.otpVerificationReducer}));
    const [otp, setOtp] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();

    console.log(isSent, sentLoading);
    const handleSend = () => {
        if(userName === ""){
            alert("Enter Email");
            return;
        }

        dispatch(resendOtpAction(userName));
    }

    const verifyOtp = () => {
        if(otp.length < 6)  return;

        dispatch(otpVerificationAction(userName, otp));
    }

    useEffect(() => {
        if(isDone){
            if(!otpData.success){
                alert("Invalid OTP");
            }
            else{
                cookies.set("email", userName, {path: "/"})
                history.push("/update-password");
                document.location.reload();
            }
        }
    }, [isDone])

    return (
        <div className='FlexBox'>
            <div className='Login'>
                <div className='LogoContainer'>
                    <img src={logo} className="Logo" />
                </div>
                <div className='MediumSpace'></div>
                <TextInput value={userName} isDisabled={isSent} setValue={(e) => setUserName(e.target.value)} label="Email" />
                <div className='MediumSpace'></div>
                {
                    isSent&&<>
                        <span className="Text5 TextLight">Enter OTP</span>
                        <OtpInput otp={otp} setOtp={setOtp} />
                    </>
                }
                {
                    isSent?
                    <PrimaryButton name={loading?"Verifing OTP...":"Verify OTP"} onClick={verifyOtp} disabled={loading} />
                    :
                    <PrimaryButton name={sentLoading?"Sending OTP...":"Send OTP"} onClick={handleSend} disabled={sentLoading} />
                }
            </div>
        </div>
    )
}

export default ForgotPassword