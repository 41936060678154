import axios from "axios"
import {BASE_URL} from "../../Constants/Constants"
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const authToken = cookies.get("authToken");

export const getUserByIdAction = () => (dispatch) => {
    const email = cookies.get("email");

    dispatch({
        type: "GET_USER_BY_ID_REQUEST",
    })

    axios.get(`${BASE_URL}/users/get/by-id?email=${email}`, {
        headers: {
            "Authorization": authToken
        }
    })
        .then((response) => {
            dispatch({
                type: "GET_USER_BY_ID_SUCCESS",
                payload: response.data
            })
        })
        .catch((err) => {
            dispatch({
                type: "GET_USER_BY_ID_FAIL",
                payload: err
            })
        })
}
