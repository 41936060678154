import axios from 'axios';
import moment from 'moment';
import React, {useEffect, useState} from 'react'
import {Table} from 'react-bootstrap';
import Loading from '../../../../Components/Loading/Loading';
import {BASE_URL} from "../../../../Constants/Constants"
import {TbExternalLink} from "react-icons/tb"
import {enrollBatchAction, getBatchAction, getUserStatusAction} from "../../../../Redux/Actions/BatchAction"
import {useSelector, useDispatch} from 'react-redux';
import Cookies from 'universal-cookie';
import {PrimaryButton} from '../../../../Components/Button/Button';
import CreatePayment from '../Payment/CreatePayment/CreatePayment';
import {getUserByIdAction} from "../../../../Redux/Actions/ProfileAction";

const cookies = new Cookies();

const Batch = () => {
    const [expiryDate, setExpiryDate] = useState("");
    const dispatch = useDispatch();
    const {mainData, loading, isShow} = useSelector(state => ({...state.getBatchReducer}));
    const {isEnrolled} = useSelector(state => ({...state.enrollBatchReducer}));
    const {isPaid} = useSelector(state => ({...state.getUserStatusReducer}));
    const {userData} = useSelector(state => ({...state.getUserByIdReducer}));
    const [isOpen, setIsOpen] = useState(false);

    const timezone = cookies.get("timezone");
    const email = cookies.get("email")

    useEffect(() => {
        getMainData();
        checkUserPaidStatus();
        dispatch(getUserByIdAction())
    }, [])

    const getMainData = () => dispatch(getBatchAction(timezone, email))
    const checkUserPaidStatus = () => dispatch(getUserStatusAction(email))
    const handleEnroll = (dt) => dispatch(enrollBatchAction(email, dt?.id))

    useEffect(() => {
        if (isEnrolled) getMainData();
    }, [isEnrolled])

    useEffect(() => {
        if (userData?.student?.expiryDate !== null && userData?.student?.expiryDate !== "") setExpiryDate(moment(userData?.student?.expiryDate).format("DD-MM-YYYY"))
    }, [userData])

    return (
        <div className='Payments'>
            <div className='SpaceBetween'>
                <div className='Text2'>Classes</div>
                <div><div className={"ExpiredText"} >Subscription Expires On: {expiryDate}</div >
                {
                    !isPaid &&
                    <PrimaryButton name="Pay Now" onClick={() => setIsOpen(true)}/>
                }
                </div>
            </div>
            {isOpen && <CreatePayment setIsOpen={setIsOpen} getMainData={getMainData} isOpen={isOpen}/>}
            <div className='MediumSpace'></div>
            {
                loading ?
                    <Loading/>
                    :
                    <div>
                        {
                            (mainData && mainData.length) ?
                                <Table hover className='PaymentTable'>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Meet Link</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        mainData?.map((dt, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{dt?.name}</td>
                                                    <td>{moment(dt?.batchTimeSlots.startDate).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                    <td>{moment(dt?.batchTimeSlots.endDate).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                    <td style={{width: "20%"}}>
                                                        {
                                                            isShow ?
                                                                (
                                                                    dt?.meetLink ?
                                                                        <a href={dt?.meetLink}
                                                                           target="_blank"><TbExternalLink/></a>
                                                                        :
                                                                        <span>-</span>
                                                                )
                                                                :
                                                                (
                                                                    !isPaid ?
                                                                        <PrimaryButton name="Enroll Now"
                                                                                       onClick={() => setIsOpen(true)}/>
                                                                        :
                                                                        <PrimaryButton name="Join this class"
                                                                                       onClick={() => handleEnroll(dt)}/>
                                                                )
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                                :
                                <div className='Text3 TextCenter MarginBox'>No Classes Scheduled</div>
                        }
                    </div>
            }
        </div>
    )
}

export default Batch
