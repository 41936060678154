const initialState = {
    updatePass : {
        loading : false,
        isDone : false
    }
}

export const updatePasswordReducer = (state = initialState.updatePass, action) => {
    switch(action.type){
        case "UPDATE_PASSWORD_REQUEST" : return {
            loading : true,
            isDone : false
        }
        case "UPDATE_PASSWORD_SUCCESS" : return {
            loading : false,
            isDone : true
        }
        case "UPDATE_PASSWORD_FAIL" : return {
            loading : false,
            isDone : false
        }
        default : 
            return state;
    }
}