import React from 'react'
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {MdOutlineBatchPrediction} from "react-icons/md"
import {FaUserCircle} from "react-icons/fa"
import {MdOutlinePayment} from "react-icons/md"

const BottomBar = ({tab, setTab}) => {
    return (
        <div className='BottomBar'>
            <Box sx={{ width: "100%" }}>
                <BottomNavigation
                    showLabels
                    value={tab}
                    onChange={(event, newValue) => {
                        console.log(newValue);
                        setTab(parseInt(newValue));
                    }}
                >
                    <BottomNavigationAction style={{color : tab==0?"#0089ff":"black"}} label="Batches" icon={<MdOutlineBatchPrediction />} />
                    <BottomNavigationAction style={{color : tab==1?"#0089ff":"black"}} label="Payments" icon={<MdOutlinePayment />} />
                    <BottomNavigationAction style={{color : tab==2?"#0089ff":"black"}} label="Profile" icon={<FaUserCircle />} />
                </BottomNavigation>
            </Box>
        </div>
    )
}

export default BottomBar