import axios from "axios"
import {BASE_URL} from "../../Constants/Constants"
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const authToken = cookies.get("authToken");

export const getBatchAction = (timezone, email) => (dispatch) => {
    dispatch({
        type: "GET_BATCHES_REQUEST",
    })

    axios.get(`${BASE_URL}/batches/get/for-student?timezone=${timezone}&studentId=${email}`, {
        headers : {
            "Authorization" : authToken
        }
    })
    .then((response) => {
        dispatch({
            type: "GET_BATCHES_SUCCESS",
            payload: response.data
        })
    })
    .catch((err) => {
        dispatch({
            type: "GET_BATCHES_FAIL",
            payload: err
        })
    })
}

export const enrollBatchAction = (email, batchId) => (dispatch) => {
    dispatch({
        type: "ENROLL_BATCH_REQUEST",
    })

    let url = `${BASE_URL}/users/update/enrolled-status?studentId=${email}&batchId=${batchId}`

    let config = {
        method: 'put',
        url: url,
        headers: { 
            'Content-Type': 'application/json', 
            'Accept': '*/*', 
            'Authorization': authToken
        },
    }
    axios(config)
    .then((response) => {
        dispatch({
            type: "ENROLL_BATCH_SUCCESS",
            payload: response.data
        })
    })
    .catch((err) => {
        dispatch({
            type: "ENROLL_BATCH_FAIL",
            payload: err
        })
    })
}

export const getUserStatusAction = (email) => (dispatch) => {
    dispatch({
        type: "GET_USER_STATUS_REQUEST",
    })

    axios.get(`${BASE_URL}/users/get/by-id?email=${email}`, {
        headers : {
            "Authorization" : authToken
        }
    })
    .then((response) => {
        dispatch({
            type: "GET_USER_STATUS_SUCCESS",
            payload: response.data
        })
    })
    .catch((err) => {
        dispatch({
            type: "GET_USER_STATUS_FAIL",
            payload: err
        })
    })
}