import axios from "axios"
import {BASE_URL} from "../../Constants/Constants"

export const loginAction = (body) => (dispatch) => {
    dispatch({
        type: "LOGIN_REQUEST",
    })

    var config = {
        method: 'post',
        url: `${BASE_URL}/users/login`,
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': '*/*'
        },
        data : body
    };

    axios(config)
    .then((response) => {
        dispatch({
            type: "LOGIN_SUCCESS",
            payload: response.data
        })
    })
    .catch((err) => {
        dispatch({
            type: "LOGIN_FAIL",
            payload: err
        })
    })
}

export const resendOtpAction = (email) => (dispatch) => {
    dispatch({
        type: "RESEND_OTP_REQUEST",
    })

    var config = {
        method: 'post',
        url: `${BASE_URL}/users/resend-otp?email=${email}`,
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': '*/*'
        },
    };

    axios(config)
    .then((response) => {
        dispatch({
            type: "RESEND_OTP_SUCCESS",
            payload: response.data
        })
    })
    .catch((err) => {
        dispatch({
            type: "RESEND_OTP_FAIL",
            payload: err
        })
    })
}