import { Select, TextField } from "@mui/material"
import "./Input.css"

export const TextInput = ({value, setValue, type, isDisabled, label, placeholder}) => {
    return(
        <div>
            {
                label&&
                <span className="Text5 TextLight">{label}</span>
            }
            <div className="LessSpace"></div>
            <TextField className="MaterialInput" variant="outlined" placeholder={placeholder || ""} type={type || "text"} value={value} onChange={setValue} disabled={isDisabled || false} />
        </div>
    )
}
