const initialState = {
    loading : false,
    isDone : false,
    loginData : {}
}

export const loginReducer = (state = initialState, action) => {
    switch(action.type){
        case "LOGIN_REQUEST" : return {
            loading : true,
            loginData : {},
            isDone : false,
        }
        case "LOGIN_SUCCESS" : return {
            loading : false,
            loginData : action.payload,
            isDone : true
        }
        case "LOGIN_FAIL" : return {
            loading: false,
            loginData : {},
            isDone : false,
        }
        default : 
            return state;
    }
}

export const resendOtpReducer = (state = {isSent : false, sentLoading : false}, action) => {
    switch(action.type){
        case "RESEND_OTP_REQUEST" : return {
            isSent : false,
            sentLoading : true
        }
        case "RESEND_OTP_SUCCESS" : return {
            isSent : true,
            sentLoading : false
        }
        case "RESEND_OTP_FAIL" : return {
            isSent : false,
            sentLoading : false
        }
        default : 
            return state;
    }
}
