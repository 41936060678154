import React from 'react'
import Login from './Pages/Login/Login'
import "./App.css"
import Register from './Pages/Register/Register'
import Dashboard from './Pages/Dashboard/Dashboard'
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import UpdatePassword from './Pages/UpdatePassword/UpdatePassword';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';

const App = () => {
    return (
        <div className='App'>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact>
                        <Login />
                    </Route>  
                    <Route path="/login" exact>
                        <Login />
                    </Route>
                    <Route path="/register" exact>
                        <Register />
                    </Route>
                    <Route path="/dashboard" exact>
                        <Dashboard />
                    </Route>
                    <Route path="/update-password" exact>
                        <UpdatePassword />
                    </Route>
                    <Route path="/forgot-password" exact>
                        <ForgotPassword />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App