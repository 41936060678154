const initialState = {
    payment: {
        mainData: [],
        loading: false
    },
    createStatus: {
        loading: false,
        isCreated: false
    },
    saveFile: {
        url: ""
    },
    createFile: {
        loading: false,
        createFileResponse: "",
        error: "",
    },
    createPaymentLink: {
        loading: false,
        response: {}
    }
}

export const getPaymentReducer = (state = initialState.payment, action) => {
    switch (action.type) {
        case "GET_PAYMENTS_REQUEST" :
            return {
                loading: true,
                mainData: [],
            }
        case "GET_PAYMENTS_SUCCESS" :
            return {
                loading: false,
                mainData: action.payload.data,
            }
        case "GET_PAYMENTS_FAIL" :
            return {
                loading: false,
                mainData: null,
            }
        default :
            return state;
    }
}

export const createPaymentReducer = (state = initialState.createStatus, action) => {
    switch (action.type) {
        case "CREATE_PAYMENT_REQUEST" :
            return {
                loading: true,
                isCreated: false
            }
        case "CREATE_PAYMENT_SUCCESS" :
            return {
                loading: false,
                isCreated: true
            }
        case "CREATE_PAYMENT_FAIL" :
            return {
                loading: false,
                isCreated: false
            }
        default :
            return state;
    }
}

export const saveFileReducer = (state = initialState.saveFile, action) => {
    switch (action.type) {
        case "SAVE_FILE_REQUEST" :
            return {
                url: ""
            }
        case "SAVE_FILE_SUCCESS" :
            return {
                url: action.payload,
            }
        case "SAVE_FILE_FAIL" :
            return {
                url: ""
            }
        default :
            return state;
    }
}

export const createPaymentLinkReducer = (state = initialState.createPaymentLink, action) => {
    switch (action.type) {
        case "CREATE_PAYMENT_LINK_REQUEST" :
            return {
                loading: true,
                response: state.response
            }
        case "CREATE_PAYMENT_LINK_SUCCESS" :
            return {
                loading: false,
                response: action.payload
            }
        case "CREATE_PAYMENT_LINK_FAIL" :
            return {
                loading: false,
                response: action.payload
            }
        default :
            return state;
    }
}
