const initialState = {
    userData: {
        loading: false,
        userData: {}
    }
}


export const getUserByIdReducer = (state = initialState.userData, action) => {
    switch (action.type) {
        case "GET_USER_BY_ID_REQUEST" :
            return {
                loading: true,
                userData: {}
            }
        case "GET_USER_BY_ID_SUCCESS" :
            return {
                loading: false,
                userData: action.payload.data,
            }
        case "GET_USER_BY_ID_FAIL" :
            return {
                loading: false,
                userData: {}
            }
        default :
            return state;
    }
}
