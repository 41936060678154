import axios from "axios"
import {BASE_URL} from "../../Constants/Constants"

export const registerAction = (body) => (dispatch) => {
    dispatch({
        type: "REGISTER_REQUEST",
    })

    var config = {
        method: 'post',
        url: `${BASE_URL}/users/STUDENT/register`,
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': '*/*'
        },
        data : body
    };

    axios(config)
    .then((response) => {
        dispatch({
            type: "REGISTER_SUCCESS",
            payload: response.data
        })
    })
    .catch((err) => {
        dispatch({
            type: "REGISTER_FAIL",
            payload: err
        })
    })
}

export const otpVerificationAction = (email, otp) => (dispatch) => {
    dispatch({
        type: "OTP_VERIFICATION_REQUEST",
    })

    var config = {
        method: 'post',
        url: `${BASE_URL}/users/verify-otp?emailId=${email}&otp=${otp}`,
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': '*/*'
        },
    };

    axios(config)
    .then((response) => {
        dispatch({
            type: "OTP_VERIFICATION_SUCCESS",
            payload: response.data
        })
    })
    .catch((err) => {
        dispatch({
            type: "OTP_VERIFICATION_FAIL",
            payload: err
        })
    })
}