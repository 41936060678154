import React, {useEffect, useState} from 'react'
import {PrimaryButton} from '../../Components/Button/Button'
import {TextInput} from '../../Components/Input/Input';

import {useHistory} from "react-router";
import "./Login.css"
import logo from "../../Images/logo.jpg"
import Cookies from 'universal-cookie';
import {useDispatch, useSelector} from 'react-redux';
import {loginAction, resendOtpAction} from '../../Redux/Actions/LoginAction';
import OtpVerification from '../Register/RegisterStep/OtpVerification';

const cookies = new Cookies();

const Login = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const {loading, isDone, loginData} = useSelector(state => ({...state.loginReducer}));
    const dispatch = useDispatch();
    const history = useHistory();
    const [tab, setTab] = useState(1);

    useEffect(() => {
        if (cookies.get("authToken")) {
            history.push("/dashboard");
            document.location.reload();
        }
    }, [])

    const handleRegister = () => {
        history.push("/register");
        document.location.reload();
    }

    const login = () => {
        if (userName === "" || password === "") {
            alert("Fill All Fields");
            return;
        }

        let data = {
            email: userName,
            password: password
        }
        dispatch(loginAction(data));
    }

    useEffect(() => {
        if (isDone) {
            if (loginData.success) {
                let res = loginData.data;
                if (!res?.verificationStatus?.verified) {
                    dispatch(resendOtpAction(userName));
                    setTab(2);
                    alert("You are not verified. Please verify yourself and login again.");
                    return;
                }
                if (res.userType.indexOf("STUDENT") == -1) {
                    alert("Invalid User Type");
                    return;
                }
                let d = new Date();
                d.setTime(d.getTime() + (1440 * 60 * 1000));

                cookies.set("authToken", "Bearer " + res.authToken, {path: "/", expires: d});
                cookies.set("timezone", res.timezone, {path: "/"});
                cookies.set("email", res.email, {path: "/"});
                cookies.set("fullName", res.fullName, {path: "/"});
                cookies.set("country", res.country, {path: "/"});
                history.push("/dashboard");
                document.location.reload();
            } else {
                alert("Invalid Credentials");
            }
        }
    }, [isDone])

    const handleForgot = () => {
        history.push("/forgot-password");
        document.location.reload();
    }

    return (
        <div className='FlexBox'>
            <div className='Login'>
                <div className='LogoContainer'>
                    <img src={logo} className="Logo"/>
                </div>
                <div className='MediumSpace'></div>
                {
                    tab == 1 ?
                        <>
                            <TextInput value={userName} setValue={(e) => setUserName(e.target.value)}
                                       placeholder="Email Address"/>
                            <div className='MediumSpace'></div>
                            <TextInput value={password} setValue={(e) => setPassword(e.target.value)} type="password"
                                       placeholder="Password"/>
                            <div className='MediumSpace'></div>
                            <PrimaryButton name={loading ? "Loging In..." : "Login"} onClick={() => login()}
                                           disabled={loading}/>
                            <div className='MediumSpace'></div>
                            <div className='Text5 TextCenter'>Don't have an Account? <span onClick={handleRegister}
                                                                                           className='RedirectText'>Register</span>
                            </div>
                            <div className='Text5 TextCenter'><span onClick={handleForgot} className='RedirectText'>Forgot Password?</span>
                            </div>
                        </>
                        :
                        <OtpVerification email={userName}/>
                }
            </div>
        </div>
    )
}

export default Login
