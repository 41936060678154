import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from '../../../Components/Button/Button';
import OtpInput from '../../../Components/OtpInput/OtpInput'
import { BASE_URL } from '../../../Constants/Constants';
import { useHistory } from "react-router";
import { otpVerificationAction, registerAction } from '../../../Redux/Actions/RegisterAction';
import { useDispatch, useSelector } from 'react-redux';

const OtpVerification = ({email}) => {
    const [otp, setOtp] = useState("");
    const { loading, isDone, otpData } = useSelector(state => ({...state.otpVerificationReducer}));
    const dispatch = useDispatch();
    const history = useHistory();

    const verifyOtp = () => {
        if(otp.length < 6)  return;

        dispatch(otpVerificationAction(email, otp));
    }

    useEffect(() => {
        if(isDone){
            if(!otpData.success){
                alert("Invalid OTP");
            }
            else{
                history.push("/login");
                document.location.reload();
            }
        }
    }, [isDone])

    return (
        <div className='OtpInput'>
            <div className='Text4 TextCenter'>Enter OTP sent to {email}</div>
            <div className='MoreSpace'></div>
            <OtpInput otp={otp} setOtp={setOtp} />
            <div className='MoreSpace'></div>
            <PrimaryButton name={loading?"Verifing OTP...":"Verify OTP"} onClick={verifyOtp} disabled={loading} />
        </div>
    )
}

export default OtpVerification