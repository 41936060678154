import React, { useEffect, useState } from 'react'
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { PrimaryButton } from '../../Components/Button/Button';
import { TextInput } from '../../Components/Input/Input';
import { updatePasswordAction } from '../../Redux/Actions/UpdatePasswordAction';
import "./UpdatePassword.css"

const cookies = new Cookies();

const UpdatePassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const { loading, isDone } = useSelector(state => ({...state.updatePasswordReducer}));
    const dispatch = useDispatch();
    const history = useHistory();
    const authToken = cookies.get('authToken') || "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJuZXdfYWRtaW5fMUB0ZXN0LmNvbSIsImV4cCI6MTY2NjI5NTMwMiwiaWF0IjoxNjY2MjA4OTAyfQ.NeKtoPbdB8wpev0PlKA--zusVdtFVEfz83cZ7DqFKBE";

    const updatePassword = () => {
        if(!password || !confirmPass || password !== confirmPass){
            alert("Password doesn't match");
            return;
        }

        const email = cookies.get("email");
        
        dispatch(updatePasswordAction(email, password, authToken));
    }

    useEffect(() => {
        if(isDone){
            cookies.remove("authToken", { path : "/" });
            cookies.remove("timezone", { path : "/" });
            cookies.remove("email", { path : "/" });
            cookies.remove("fullName", { path : "/" });
            cookies.remove("affiliateId", { path : "/" });
            cookies.remove("userType", { path : "/" });
            history.push({ pathname : "/"})
            document.location.reload();
        }
    }, [isDone])

    const handleBack = () => {
        history.push({ pathname : "/"})
        document.location.reload();
    }

    return (
        <div className='FlexBox'>
            <div className='Login'>
                <TextInput value={password} setValue={(e) => setPassword(e.target.value)} type="password" label="Password" />
                <div className='MediumSpace'></div>
                <TextInput value={confirmPass} setValue={(e) => setConfirmPass(e.target.value)} label="Confirm Password" />
                <div className='MediumSpace'></div>
                <PrimaryButton name={!loading?"Update Password":"Updating..."} onClick={updatePassword} disabled={loading} />
                <div className='MediumSpace'></div>
                <div className='MediumSpace'></div>
                {
                    cookies.get('authToken')&&
                    <div className='BackContainer' onClick={handleBack}>
                        <MdOutlineArrowBackIos /> Back to Dashboard
                    </div>
                }
            </div>
        </div>
    )
}

export default UpdatePassword