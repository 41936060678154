import React from 'react'
import {Spinner} from 'react-bootstrap';
import "./Loading.css"

const Loading = () => {
    return (
        <div className='FlexBox MarginBox'>
            <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
            />
        </div>
    )
}

export default Loading