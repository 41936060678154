import { Modal } from 'react-bootstrap';
import React, { useState } from 'react'
import SubmitPayment from "./Components/SubmitPayment";
import ViewPaymentDetail from "./Components/ViewPaymentDetail";
import {countryDetailsMap} from "../../../../../Constants/Constants";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const CreatePayment = ({setIsOpen, isOpen, getMainData}) => {
    const country = cookies.get("country");
    const expected = countryDetailsMap.get(country).paymentInfo;

    const [tab, setTab] = useState(0);
    const [selectedDuration, setSelectedDuration] = useState(30);
    const [expectedAmount, setExpectedAmount] = useState(expected[selectedDuration]);

    const handleHide = () => {
        setIsOpen(false);
        getMainData();
    }

    return (
        <Modal centered show={isOpen} size="lg" onHide={handleHide}>
            {
                tab?
                <SubmitPayment
                    setTab={setTab} handleHide={handleHide}
                    expectedAmount={expectedAmount}
                    selectedDuration={selectedDuration}
                    setExpectedAmount={setExpectedAmount} />
                :
                <ViewPaymentDetail
                    setTab={setTab} handleHide={handleHide}
                    expectedAmount={expectedAmount}
                    selectedDuration={selectedDuration}
                    setExpectedAmount={setExpectedAmount}
                    setSelectedDuration={setSelectedDuration} />
            }
        </Modal>
    )
}

export default CreatePayment
