import React, {useEffect, useState} from 'react'
import BottomBar from '../../Components/BottomBar/BottomBar';
import SideBar from '../../Components/SideBar/SideBar'
import "./Dashboard.css"
import Batch from './DashboardComponent/Batch/Batch';
import Payment from './DashboardComponent/Payment/Payment';
import Profile from './DashboardComponent/Profile/Profile';
import logo from "../../Images/logo.jpg";
import Cookies from 'universal-cookie';
import {useHistory} from 'react-router-dom';

const cookies = new Cookies();

const Dashboard = () => {
    const [tab, setTab] = useState(0);
    const history = useHistory();

    const selectComponent = () => {
        switch (tab) {
            case 0 :
                return <Batch/>
            case 1 :
                return <Payment/>
            case 2 :
                return <Profile/>
        }
    }

    useEffect(() => {
        if (!cookies.get("authToken")) {
            history.push("/login");
            document.location.reload();
        }
    }, [])

    return (
        <div className='Dashboard'>
            <SideBar tab={tab} setTab={setTab}/>
            <div className='MainContent'>
                <div className='CompactLogo'>
                    <img src={logo} className="Logo"/>
                </div>
                {selectComponent()}
            </div>
            <BottomBar tab={tab} setTab={setTab}/>
        </div>
    )
}

export default Dashboard
