import React from 'react'
import logo from "../../Images/logo.jpg"
import {ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import {MdOutlineBatchPrediction} from "react-icons/md"
import {FaUserCircle} from "react-icons/fa"
import {MdOutlinePayment} from "react-icons/md"
import {BiLogOut} from "react-icons/bi"

import "./SideBar.css"
import {useHistory} from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const SideBar = ({tab, setTab}) => {
    const history = useHistory();

    const handleLogOut = () => {
        cookies.remove("authToken", {path: "/"});
        cookies.remove("timezone", {path: "/"});
        cookies.remove("email", {path: "/"});
        cookies.remove("fullName", {path: "/"});
        history.push({pathname: "/"})
        document.location.reload();
    }

    return (
        <ProSidebar style={{width: "100%"}} className='SideBar'>
            <SidebarHeader>
                <div>
                    <img src={logo} className="Logo"/>
                </div>
            </SidebarHeader>
            <SidebarContent>
                <Menu iconShape="circle">
                    <MenuItem onClick={() => setTab(0)} className={tab == 0 ? "SelectedMenu" : "MenuItem"}
                              icon={<MdOutlineBatchPrediction className='MenuIcon'/>}>Classes</MenuItem>
                    <MenuItem onClick={() => setTab(1)} className={tab == 1 ? "SelectedMenu" : "MenuItem"}
                              icon={<MdOutlinePayment className='MenuIcon'/>}>Subscriptions</MenuItem>
                    <MenuItem onClick={() => setTab(2)} className={tab == 2 ? "SelectedMenu" : "MenuItem"}
                              icon={<FaUserCircle className='MenuIcon'/>}>Profile</MenuItem>
                </Menu>
            </SidebarContent>
            <SidebarFooter>
                <Menu iconShape="circle">
                    <MenuItem onClick={handleLogOut} className={"MenuItem"} icon={<BiLogOut className='MenuIcon'/>}>Log
                        Out</MenuItem>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
    )
    // return (
    //     <div className='SideBar'>
    //         <div>
    //             <img src={logo} className="Logo" />
    //         </div>
    //         <div className='MoreSpace'></div>
    //         <div className='MoreSpace'></div>
    //         <div className='Menu'>
    //             <div className={tab == 0?'Selected MenuItem':'MenuItem'} onClick={() => setTab(0)}>
    //                 <div className='MenuSide'></div>
    //                 <div className='MenuIconContainer'>
    //                     <MdOutlineBatchPrediction className='MenuIcon' />
    //                 </div>
    //                 <div className='Text5'>Batches</div>
    //             </div>
    //             <div className={tab == 1?'Selected MenuItem':'MenuItem'} onClick={() => setTab(1)}>
    //                 <div className='MenuSide'></div>
    //                 <div className='MenuIconContainer'>
    //                     <MdOutlinePayment className='MenuIcon' />
    //                 </div>
    //                 <div className='Text5'>Payments</div>
    //             </div>
    //             <div className={tab == 2?'Selected MenuItem':'MenuItem'} onClick={() => setTab(2)}>
    //                 <div className='MenuSide'></div>
    //                 <div className='MenuIconContainer'>
    //                     <FaUserCircle className='MenuIcon' />
    //                 </div>
    //                 <div className='Text5'>Profile</div>
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default SideBar
