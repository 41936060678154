import React, {useEffect, useState} from 'react'
import {PrimaryButton} from '../../../Components/Button/Button';
import {TextInput} from '../../../Components/Input/Input'
import {countryDetailsMap, getCountriesArr} from '../../../Constants/Constants';
import Card from 'react-bootstrap/Card';
import {MenuItem, Select} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {registerAction} from '../../../Redux/Actions/RegisterAction';

const MainDetails = ({body, setBody, setTab, setCountry}) => {
    const affId = (new URLSearchParams(window.location.search)).get("affId");
    const {loading, isDone, registerData} = useSelector(state => ({...state.registerReducer}));
    const dispatch = useDispatch();

    useEffect(() => {
        handleChange("timezone", countryDetailsMap.get(body.country).timezoneInfo[0]?.value);
    }, [body.country]);

    const handleChange = (name, val) => {
        console.log(name, val)
        if (name === "contactNo" && (val.length > 10 || isNaN(val))) {
            return;
        }
        setBody({
            ...body,
            [name]: val
        });
    }

    const saveDetail = () => {
        if (body.email === "" || body.contactNo.length < 10 || body.fullName === "" || body.password === "") {
            alert("Fill All Fields");
            return;
        }

        let obj = body;
        if (affId) {
            obj = {
                ...body,
                student: {
                    affiliateId: affId
                }
            }
        }

        dispatch(registerAction(obj));
    }

    useEffect(() => {
        if (isDone) {
            if (registerData.success)
                setTab(2);
            else {
                alert(registerData.message);
            }
        }
    }, [isDone])

    return (
        <div>
            <TextInput value={body.email} label="Email" setValue={(e) => handleChange("email", e.target.value)}/>
            <div className='LessSpace'></div>
            <TextInput value={body.contactNo} label="Contact Number"
                       setValue={(e) => handleChange("contactNo", e.target.value)}/>
            <div className='LessSpace'></div>
            <TextInput value={body.fullName} label="Full Name"
                       setValue={(e) => handleChange("fullName", e.target.value)}/>
            <div className='LessSpace'></div>
            <TextInput value={body.password} label="Password" type="password"
                       setValue={(e) => handleChange("password", e.target.value)}/>
            <div className='LessSpace'></div>
            <span className="Text5 TextLight">Select Country</span>
            <div className="LessSpace"></div>
            <div className='SpaceBetween FlagContainer'>
                {
                    Object.keys(getCountriesArr()).map((key) => {
                        const value = getCountriesArr()[key]

                        return (
                            <Card className={body.country === key ? "SelectedCountry CountryFlag" : "CountryFlag"}
                                  onClick={() => handleChange("country", key)}>
                                <Card.Img width={"100%"} height={"100%"} variant="top" src={value.flag}/>
                            </Card>
                        )
                    })
                }
            </div>
            <div className="LessSpace"></div>
            <span className="Text5 TextLight">Timezone</span>
            <div className="LessSpace"></div>
            <Select
                className="MaterialSelect MaterialInput" variant="outlined"
                value={body?.timezone}
                onChange={(e) => handleChange("timezone", e.target.value)}
                disabled={countryDetailsMap.get(body?.country).timezoneInfo.length < 2}>
                {
                    countryDetailsMap.get(body?.country).timezoneInfo?.map((dt, index) => {
                        return <MenuItem key={index} value={dt?.value}>{dt?.name}</MenuItem>
                    })
                }
            </Select>
            <div className='MoreSpace'></div>
            <PrimaryButton name={loading ? "Sending OTP..." : "Get OTP"} onClick={saveDetail} disabled={loading}/>
        </div>
    )
}

export default MainDetails
