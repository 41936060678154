import React, { useEffect , useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { PrimaryButton } from '../../../../Components/Button/Button';
import { TextInput } from '../../../../Components/Input/Input';
import Loading from '../../../../Components/Loading/Loading'
import { getUserByIdAction } from '../../../../Redux/Actions/ProfileAction';
import "./Profile.css"
import userAvatar from "../../../../Images/userAvatar.jpeg"
import moment from "moment";

const cookies = new Cookies();

const Profile = () => {
    const [expiryDate, setExpiryDate] = useState("Not Applicable");
    const { loading, userData } = useSelector(state => ({...state.getUserByIdReducer}));
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getUserByIdAction());
    }, [])

    useEffect(() => {
        if (userData?.student?.expiryDate !== null && userData?.student?.expiryDate !== "") setExpiryDate(moment(userData?.student?.expiryDate).format("DD-MM-YYYY"))
    }, [userData])

    const handleLogOut = () => {
        cookies.remove("authToken", { path : "/" });
        cookies.remove("timezone", { path : "/" });
        cookies.remove("email", { path : "/" });
        cookies.remove("fullName", { path : "/" });
        history.push({ pathname : "/"})
        document.location.reload();
    }

    const handleUpdatePassword = () => {
        history.push({ pathname : "/update-password"});
        document.location.reload();
    }

    return (
        <div className='Payments'>
            <div className='SpaceBetween'>
                <div className='Text2'>Profile</div>
                <span className='LogoutButton'><PrimaryButton name="Log Out" onClick={handleLogOut} /></span>
            </div>
            <div className='MoreSpace'></div>
            <div className='MoreSpace'></div>
            <div className='MoreSpace'></div>
            {
                loading?
                <Loading />
                :
                <div className='Profile'>
                    <div className='ProfileLeft'>
                        <div className='ProfileImageContainer'>
                            <img src={userData?.teacher?.profilePic || userAvatar} style={{width : "100%", borderRadius : "50%"}} />
                        </div>
                        <div className='LessSpace'></div>
                        <div className='MoreSpace'></div>
                        <div className='MoreSpace'></div>
                        <div className='Text1'>{userData?.fullName}</div>
                        <div className='Text3'>Student</div>
                        <PrimaryButton name="Update Password" onClick={handleUpdatePassword} />
                    </div>
                    <div className='ProfileRight'>
                        <TextInput label="Email" value={userData?.email} isDisabled={true} setValue={() => {}} />
                        <div className='MediumSpace'></div>
                        <TextInput label="Phone Number" value={userData?.contactNo} isDisabled={true} setValue={() => {}} />
                        <div className='MediumSpace'></div>
                        <TextInput label="Registeration Data" value={userData?.registeredDate} isDisabled={true} setValue={() => {}} />
                        <div className='MediumSpace'></div>
                        <TextInput label="Country" value={userData?.country} isDisabled={true} setValue={() => {}} />
                        <div className='MediumSpace'></div>
                        <TextInput label="Time Zone" value={userData?.timezone} isDisabled={true} setValue={() => {}} />
                        <div className='MediumSpace'></div>
                        <TextInput label="Expiry Date" value={expiryDate} isDisabled={true} setValue={() => {}} />
                        <div className='MediumSpace'></div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Profile
