import React, {useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Cookies from 'universal-cookie';
import {PrimaryButton} from '../../../../../../Components/Button/Button';
import {InputAdornment, MenuItem, OutlinedInput, Select} from "@mui/material";
import {countryDetailsMap, SQUARE_UP_LOCATION_ID} from "../../../../../../Constants/Constants";
import {v4 as uuidv4} from 'uuid';
import {useDispatch, useSelector} from "react-redux";
import {createPaymentLinkAction} from "../../../../../../Redux/Actions/PaymentAction";
import Loading from "../../../../../../Components/Loading/Loading";

const cookies = new Cookies();

const ViewPaymentDetail = ({setTab, expectedAmount, setExpectedAmount, selectedDuration, setSelectedDuration}) => {
    const dispatch = useDispatch();
    const squareUpRef = useRef(null)
    const country = cookies.get("country");
    const expected = countryDetailsMap.get(country).paymentInfo;
    const [generatedPaymentLink, setGeneratedPaymentLink] = useState("");
    const [paymentLinkReq, setPaymentLinkReq] = useState({
        "idempotency_key": uuidv4(),
        "quick_pay": {
            "name": selectedDuration.toString() + " Day Course",
            "price_money": {
                "amount": parseInt(expectedAmount) * 100,
                "currency": countryDetailsMap.get(country).currency
            },
            "location_id": SQUARE_UP_LOCATION_ID
        }
    });

    const createPaymentLinkRes = useSelector(state => state.createPaymentLinkReducer);

    useEffect(() => {
        if (createPaymentLinkRes.response?.success === true) {
            const paymentLink = createPaymentLinkRes.response?.data?.payment_link?.long_url || ""
            setGeneratedPaymentLink(paymentLink)

            if (paymentLink !== "") {
                handleLinkClick(paymentLink)
            }
        }
    }, [createPaymentLinkRes]);

    const handleLinkClick = (paymentLink) => {
        if (squareUpRef.current) {
            squareUpRef.current.href = paymentLink
            squareUpRef.current.click();
        }
    }

    const handleExpectedAmountChange = (newAmount) => {
        setGeneratedPaymentLink("")
        setPaymentLinkReq((prevState) => ({
            ...prevState,
            quick_pay: {
                ...prevState.quick_pay,
                price_money: {
                    ...prevState.quick_pay.price_money,
                    amount: parseInt(newAmount) * 100
                }
            }
        }))

        setExpectedAmount(newAmount)
    }

    const getPaymentLink = () => {
        if (generatedPaymentLink !== "") {
            handleLinkClick(generatedPaymentLink)
            return;
        }

        if (country === "VIETNAM") {
            dispatch(createPaymentLinkAction(paymentLinkReq))
        } else alert("This payment method is only available for specific countries")
    }

    return (
        <>
            <Modal.Header closeButton={!createPaymentLinkRes.loading}>
                {<>
                    <Modal.Title>Step 1: Select a package to view fees</Modal.Title>
                </>}
            </Modal.Header>
            <Modal.Body style={{padding: "0"}}>
                {
                    createPaymentLinkRes.loading ? <Loading/> :
                        <>
                            <div className={"ImportantNote"}>
                                <a ref={squareUpRef} style={{display: "hidden"}} href={generatedPaymentLink}
                                   target="_blank"/>
                                <span className="Text5 TextLight">Select Duration</span>
                                <Select className="MaterialSelect MaterialInput" variant="outlined"
                                        value={selectedDuration}
                                        onChange={(e) => {
                                            setSelectedDuration(parseInt(e.target.value));
                                            handleExpectedAmountChange(expected[parseInt(e.target.value)])
                                        }}>
                                    <MenuItem value="30">30 Days</MenuItem>
                                    <MenuItem value="60">60 Days</MenuItem>
                                    <MenuItem value="90">90 Days</MenuItem>
                                </Select>
                                <div className='MediumSpace'></div>
                                <span className="Text5 TextLight">Expected Amount</span>
                                <div>
                                    <OutlinedInput
                                        className="MaterialInput"
                                        style={{width: "100%"}}
                                        value={expectedAmount}
                                        disabled
                                        startAdornment={<InputAdornment
                                            position="start">{countryDetailsMap.get(country).currency}</InputAdornment>}
                                    />
                                </div>
                            </div>

                            <h4 style={{
                                padding: "60px 10px 10px 20px"
                            }}>Step 2: Choose a payment method & view account details</h4>
                            <p className={"ImportantNotePrimary"}>Note: After making the payment, share the transaction
                                details with
                                us to
                                access the classes</p>
                            {
                                countryDetailsMap.get(country).paymentDetails(getPaymentLink, createPaymentLinkRes.loading)
                            }
                        </>
                }
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div></div>
                <PrimaryButton name="Click here if paid" onClick={() => setTab(1)}/>
            </Modal.Footer>
        </>
    )
}

export default ViewPaymentDetail
