import axios from "axios"
import {BASE_URL} from "../../Constants/Constants"
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const authToken = cookies.get("authToken");

export const getPaymentAction = (email, timezone) => (dispatch) => {
    dispatch({
        type: "GET_PAYMENTS_REQUEST",
    })

    axios.get(`${BASE_URL}/students/payment-submissions/get/by-student-id?timezone=${timezone}&studentId=${email}`, {
        headers: {
            "Authorization": authToken
        }
    })
        .then((response) => {
            dispatch({
                type: "GET_PAYMENTS_SUCCESS",
                payload: response.data
            })
        })
        .catch((err) => {
            dispatch({
                type: "GET_PAYMENTS_FAIL",
                payload: err
            })
        })
}

export const createPaymentAction = (body) => (dispatch) => {
    dispatch({
        type: "CREATE_PAYMENT_REQUEST",
    })

    var config = {
        method: 'post',
        url: `${BASE_URL}/students/payment-submissions/new-entry`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken,
            'Accept': '*/*'
        },
        data: body
    };
    axios(config)
        .then((response) => {
            dispatch({
                type: "CREATE_PAYMENT_SUCCESS",
                payload: response.data
            })
            setTimeout(() => {
                dispatch({
                    type: "CREATE_PAYMENT_FAIL",
                    payload: ""
                })
            }, 1000)
        })
        .catch((err) => {
            dispatch({
                type: "CREATE_PAYMENT_FAIL",
                payload: err
            })
        })
}


export const saveFileAction = (file) => (dispatch) => {
    dispatch({
        type: "SAVE_FILE_REQUEST",
    })

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authToken);

    var formdata = new FormData();
    formdata.append("file", file);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    let temp = Math.floor(Math.random() * 1000000);
    fetch(`${BASE_URL}/files-management/save-file?path=payment-submissions/${temp}/${file?.name.replace(".", "-")}`, requestOptions)
        .then(response => response.json())
        .then((result) => {
            dispatch({
                type: "SAVE_FILE_SUCCESS",
                payload: result.data
            })
            setTimeout(() => {
                dispatch({
                    type: "SAVE_FILE_FAIL",
                    payload: ""
                })
            }, 1000)
        })
        .catch((err) => {
            dispatch({
                type: "SAVE_FILE_FAIL",
                payload: err
            })
        })
}


export const createPaymentLinkAction = (payload) => (dispatch) => {
    dispatch({
        type: "CREATE_PAYMENT_LINK_REQUEST",
    });

    var config = {
        method: 'post',
        url: `${BASE_URL}/students/payment-submissions/trigger/square-payment`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken,
            'Accept': '*/*'
        },
        data: payload
    };

    axios(config)
        .then((response) => {
            dispatch({
                type: "CREATE_PAYMENT_LINK_SUCCESS",
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: "CREATE_PAYMENT_LINK_FAIL",
                payload: error
            });
        });
}
