import axios from "axios"
import {BASE_URL} from "../../Constants/Constants"
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const updatePasswordAction = (email, password, authToken) => async(dispatch) => {
    dispatch({
        type: "UPDATE_PASSWORD_REQUEST"
    })
    let config = {
        method: 'put',
        url: `${BASE_URL}/users/reset-safe-pin?email=${email}&safePin=${password}`,
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': authToken
        },
    }
    axios(config)
    .then(function (response) {
        dispatch({
            type: "UPDATE_PASSWORD_SUCCESS",
            payload: response.data
        })
    })
    .catch(function (error) {
        dispatch({
            type: "UPDATE_PASSWORD_FAIL",
            payload: error
        })
    });
}
