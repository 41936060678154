import React from 'react'
import ReactOtpInput from "react-otp-input-rc-17";

const OtpInput = ({otp, setOtp}) => {
    return (
        <div className='OtpContainer'>
            <ReactOtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={{
                    width: "40px",
                    height: "40px",
                    borderColor: "#CEDBEA",
                    borderWidth: 1,
                    borderRadius: 2,
                }}
                autocomplete="off"
                isInputNum="true"
                separator={<span> </span>}
            />
        </div>
    )
}

export default OtpInput