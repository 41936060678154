import { combineReducers } from "redux";

import {
    getPaymentReducer,
    createPaymentReducer,
    saveFileReducer,
    createPaymentLinkReducer
} from "./PaymentReducer";
import { getBatchReducer, enrollBatchReducer, getUserStatusReducer } from "./BatchReducer";
import { getUserByIdReducer } from "./ProfileReducer";
import { loginReducer, resendOtpReducer } from "./LoginReducer";
import { registerReducer, otpVerificationReducer } from "./RegisterReducer";
import { updatePasswordReducer } from "./UpdatePasswordReducer";

export const rootReducer = combineReducers({
    getPaymentReducer,
    createPaymentReducer,
    saveFileReducer,
    createPaymentLinkReducer,
    getBatchReducer,
    enrollBatchReducer,
    getUserByIdReducer,
    getUserStatusReducer,
    loginReducer,
    registerReducer,
    otpVerificationReducer,
    resendOtpReducer,
    updatePasswordReducer
})
