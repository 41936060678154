import axios from 'axios';
import React, {useEffect, useState} from 'react'
import {PrimaryButton} from '../../../../Components/Button/Button';
import Loading from '../../../../Components/Loading/Loading';
import {BASE_URL} from "../../../../Constants/Constants"
import CreatePayment from './CreatePayment/CreatePayment';
import {Table} from 'react-bootstrap';
import "./Payment.css"
import moment from 'moment';
import {BsFillPatchExclamationFill} from "react-icons/bs"
import {FcApproval} from "react-icons/fc";
import {MdCancel} from "react-icons/md"
import {getPaymentAction, createPaymentAction, saveFileAction} from "../../../../Redux/Actions/PaymentAction"
import {useSelector, useDispatch} from 'react-redux';
import Cookies from 'universal-cookie';
import {getUserByIdAction} from "../../../../Redux/Actions/ProfileAction";
import {getUserByIdReducer} from "../../../../Redux/Reducers/ProfileReducer";

const cookies = new Cookies();

const Payment = () => {
    const [expiryDate, setExpiryDate] = useState("");
    const dispatch = useDispatch();
    const {mainData, loading} = useSelector(state => ({...state.getPaymentReducer}));
    const {userData} = useSelector(state => ({...state.getUserByIdReducer}));

    const [isOpen, setIsOpen] = useState(false);

    const email = cookies.get("email");
    const timezone = cookies.get("timezone");

    useEffect(() => {
        if (userData?.student?.expiryDate !== null && userData?.student?.expiryDate !== "") setExpiryDate(moment(userData?.student?.expiryDate).format("DD-MM-YYYY"))
    }, [userData])

    useEffect(() => getMainData(), [])
    const getMainData = () => {
        dispatch(getPaymentAction(email, timezone))
        dispatch(getUserByIdAction())
    }

    return (
        <div className='Payments'>
            <div className='SpaceBetween'>
                <div className='Text2'>Subscriptions</div>
                <div>
                    <div className={"ExpiredText"} >Subscription Expires On: {expiryDate}</div >
                    <PrimaryButton name="Pay to Subscribe" onClick={() => setIsOpen(true)}/>
                </div>
            </div>
            <div className='MediumSpace'></div>
            {isOpen && <CreatePayment setIsOpen={setIsOpen} getMainData={getMainData} isOpen={isOpen}/>}
            {
                loading ?
                    <Loading/>
                    :
                    <div>
                        {
                            (mainData && mainData.length) ?
                                <Table hover className='PaymentTable'>
                                    <thead>
                                    <tr>
                                        <th>Duration</th>
                                        <th>Payment Date</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        mainData?.map((dt, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{dt?.daysToAdd || 0} Days</td>
                                                    <td>{moment(dt?.paymentDate).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                    <td>{dt?.amountPaid?.currency === "INR" ? "INR " : "AUD "} {dt?.amountPaid?.amount}</td>
                                                    {/*<td>{dt?.approvalStatus === "PENDING"?<BsFillPatchExclamationFill style={{color : "rgb(179, 179, 0)"}} />:(dt?.approvalStatus === "APPROVED"?<FcApproval />: <MdCancel style={{color: "red"}} />)}</td>*/}
                                                    <td>{dt?.approvalStatus}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                                :
                                <div className='Text3 TextCenter MarginBox'>No Payment Submission found</div>
                        }
                    </div>
            }
        </div>
    )
}

export default Payment
