const initialState = {
    batches: {
        loading: false,
        mainData: [],
        isShow: false
    },
    enroll: {
        isEnrolled: false,
    },
    userStatus: {
        isPaid: false
    }
}

export const getBatchReducer = (state = initialState.batches, action) => {
    switch (action.type) {
        case "GET_BATCHES_REQUEST":
            return {
                loading: true,
                mainData: [],
                isShow: false
            }
        case "GET_BATCHES_SUCCESS": {
            let data = action.payload.data;
            let count = 0;
            data.forEach((dt) => {
                if (dt?.meetLink) count++;
            })

            return {
                loading: false,
                mainData: data,
                isShow: count === 1
            }
        }
        case "GET_BATCHES_FAIL":
            return {
                loading: false,
                mainData: null,
                isShow: false
            }
        default:
            return state;
    }
}

export const enrollBatchReducer = (state = initialState.enroll, action) => {
    switch (action.type) {
        case "ENROLL_BATCH_REQUEST":
            return {
                isEnrolled: false
            }
        case "ENROLL_BATCH_SUCCESS":
            return {
                isEnrolled: true
            }
        case "ENROLL_BATCH_FAIL":
            return {
                isEnrolled: false
            }
        default:
            return state;
    }
}

export const getUserStatusReducer = (state = initialState.userStatus, action) => {
    switch (action.type) {
        case "GET_USER_STATUS_REQUEST":
            return {
                isPaid: false
            }
        case "GET_USER_STATUS_SUCCESS": {
            let isPaidCheck = action.payload.data?.student?.expiryDate !== null;
            return {
                isPaid: isPaidCheck
            }
        }
        case "GET_USER_STATUS_FAIL":
            return {
                isPaid: false
            }
        default:
            return state;
    }
}
