const initialState = {
    register : {
        loading : false,
        isDone : false,
        registerData : {}
    },
    otp : {
        otpData : {},
        isDone : false,
        loading : false
    }
}

export const registerReducer = (state = initialState.register, action) => {
    switch(action.type){
        case "REGISTER_REQUEST" : return {
            loading : true,
            registerData : {},
            isDone : false,
        }
        case "REGISTER_SUCCESS" : return {
            loading : false,
            registerData : action.payload,
            isDone : true
        }
        case "REGISTER_FAIL" : return {
            loading: false,
            registerData : {},
            isDone : false,
        }
        default : 
            return state;
    }
}

export const otpVerificationReducer = (state = initialState.otp, action) => {
    switch(action.type){
        case "OTP_VERIFICATION_REQUEST" : return {
            loading : true,
            otpData : {},
            isDone : false,
        }
        case "OTP_VERIFICATION_SUCCESS" : return {
            loading : false,
            otpData : action.payload,
            isDone : true
        }
        case "OTP_VERIFICATION_FAIL" : return {
            loading: false,
            otpData : {},
            isDone : false,
        }
        default : 
            return state;
    }
}