import indianFlag from "../Images/IndianFlag.png";
import ausFlag from "../Images/AusFlag.png";
import vietnam from "../Images/vietnam.png";
import {TextInput} from "../Components/Input/Input";
import React from "react";
import {PrimaryButton} from "../Components/Button/Button";

export const SQUARE_UP_LOCATION_ID = "L9NEDZT36TGPT";

export const BASE_URL = "https://cloud.readysetengo.in";
// export const BASE_URL = "https://corsproxy.io/?https://cloud.readysetengo.com";

export const countryDetailsMap = new Map([
    [
        "AUSTRALIA",
        {
            "timezoneInfo": [
                {name: "AWST", value: "Australia/Perth"},
                {name: "ACWST", value: "Australia/Eucla"},
                {name: "ACST", value: "Australia/Darwin"},
                {name: "AEST", value: "Australia/Brisbane"},
                {name: "ACDT", value: "Australia/Adelaide"},
                {name: "AEDT", value: "Australia/Sydney"},
                {name: "LHDT", value: "Australia/Lord_Howe"}
            ],
            "paymentInfo": {
                30: "300",
                60: "500",
                90: "700"
            },
            "currency": "AUD",
            "flag": ausFlag,
            "paymentDetails": () => (
                <div>
                    <div>
                        <div style={{padding: "10px 30px"}}>
                            <TextInput value="Preeti Lamba" label="Account Holder Name"
                                       isDisabled={true}/>
                            <div className='MediumSpace'></div>
                            <TextInput value="774001" label="BSB Code" isDisabled={true}/>
                            <div className='MediumSpace'></div>
                            <TextInput value="206421190" label="Account Number" isDisabled={true}/>

                        </div>
                    </div>
                </div>
            )
        }
    ],
    [
        "INDIA",
        {
            "timezoneInfo": [{name: "IST", value: "IST"}],
            "paymentInfo": {
                30: "7000",
                60: "12000",
                90: "16500"
            },
            "currency": "INR",
            "flag": indianFlag,
            "paymentDetails": () => (
                <div className='IndianPayment'>
                    <div className='IndianPaymentLeft'>
                        <div className={"PaymentSelected"}>IMPS</div>
                        {/* <div className={paymentType == 0 ? "PaymentUnselected" : "PaymentSelected"}
                                     onClick={() => setPaymentType(1)}>UPI
                                </div> */}
                    </div>
                    <div>
                        {/* {
                                    paymentType ?
                                        <div className="QrCodeContainer">
                                            <img src={qrCode} className='QrCode'/>
                                            <div><b>UPI Handle</b> : 9826831306@paytm</div>
                                        </div>
                                        : */}
                        <div style={{padding: "10px 30px"}}>
                            <TextInput value="Preeti Lamba" label="Account Holder Name"
                                       isDisabled={true}/>
                            <div className='MediumSpace'></div>
                            <TextInput value="HDFC Bank" label="Bank Name" isDisabled={true}/>
                            <div className='MediumSpace'></div>
                            <TextInput value="50100594772102" label="Account Number" isDisabled={true}/>
                            <div className='MediumSpace'></div>
                            <TextInput value="HDFC0001109" label="IFSC Code" isDisabled={true}/>
                        </div>
                        {/* } */}
                    </div>
                </div>
            )
        }
    ],
    [
        "VIETNAM",
        {
            "timezoneInfo": [
                {name: "ICT", value: "Asia/Ho_Chi_Minh"}
            ],
            "paymentInfo": {
                30: "250",
                60: "450",
                90: "600"
            },
            "currency": "AUD", // Assuming AUD is used for the square payment integration as per the note.
            "flag": vietnam,
            "paymentDetails": (getPaymentLink) => {
                return (
                    <div>
                        <div style={{padding: "20px"}}>
                            <PrimaryButton name="Click here to pay (Ignore if paid)" onClick={getPaymentLink} />
                        </div>
                    </div>
                )
            }
        }
    ],
    // [
    //     "NEPAL",
    //     {
    //         "timezoneInfo": [
    //             {name: "NPT", value: "Asia/Kathmandu"}
    //         ],
    //         "paymentInfo": {
    //             30: "4000",
    //             60: "6500",
    //             90: "9000"
    //         },
    //         "currency": "INR",
    //         "flag": Nepal,
    //         "paymentDetails": () => (
    //             <>This is Nepal</>
    //         )
    //     }
    // ]
]);


export const getCountriesArr = () => {
    let countriesMap = {};
    countryDetailsMap.forEach((value, key) => {
        countriesMap[key] = value
    });

    return countriesMap;
}
