import React, {useEffect, useState} from 'react'
import {useHistory} from "react-router";
import "./Register.css"
import MainDetails from './RegisterStep/MainDetails';
import OtpVerification from './RegisterStep/OtpVerification';
import logo from "../../Images/logo.jpg"
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Register = () => {
    const [country, setCountry] = useState(0);
    const history = useHistory();
    const [tab, setTab] = useState(1);
    const [body, setBody] = useState({
        "email": "",
        "fullName": "",
        "contactNo": "",
        "userType": [
            "STUDENT"
        ],
        "country": "INDIA",
        "timezone": "IST",
        "password": ""
    })

    useEffect(() => {
        if (cookies.get("authToken")) {
            history.push("/dashboard");
            document.location.reload();
        }
    }, [])

    const handleRegister = () => {
        history.push("/login");
        document.location.reload();
    }

    const selectComponent = () => {
        switch (tab) {
            case 1 :
                return <MainDetails body={body} setBody={setBody} setTab={setTab} setCountry={setCountry}/>
            case 2 :
                return <OtpVerification email={body?.email}/>
        }
    }

    return (
        <div className='Login'>
            <div className='LogoContainer FlexBox'>
                <img src={logo} className="Logo"/>
            </div>
            <h4 style={{paddingTop: "30px"}}>Student Registration Form</h4>
            <div className='MediumSpace'></div>
            {selectComponent()}
            <div className='MediumSpace'></div>
            <div className='Text5 TextCenter'>Already have an Account? <span onClick={handleRegister}
                                                                             className='RedirectText'>Login</span></div>
        </div>
    )
}

export default Register
