import {InputAdornment, MenuItem, OutlinedInput, Select} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux';
import Cookies from 'universal-cookie';
import {PrimaryButton} from '../../../../../../Components/Button/Button';
import {createPaymentAction, saveFileAction} from '../../../../../../Redux/Actions/PaymentAction';
import {countryDetailsMap} from "../../../../../../Constants/Constants";

const cookies = new Cookies();

const SubmitPayment = ({setTab, handleHide, selectedDuration, expectedAmount, setExpectedAmount}) => {
    const dispatch = useDispatch();
    const email = cookies.get("email");
    const country = cookies.get("country");
    const expected = countryDetailsMap.get(country).paymentInfo;

    const [paymentProof, setPaymentProof] = useState({
        file: "",
        url: "",
        name: "No File Chosen",
    });
    const [body, setBody] = useState({
        "studentId": email,
        "amountPaid": {
            "amount": "",
            "currency": countryDetailsMap.get(country).currency
        },
        "daysToAdd": selectedDuration,
        "paymentProof": ""
    });

    const {url} = useSelector(state => ({...state.saveFileReducer}));
    const {loading, isCreated} = useSelector(state => ({...state.createPaymentReducer}));

    useEffect(() => {
        if (url) {
            dispatch(createPaymentAction({...body, paymentProof: url}));
        }
    }, [url])

    useEffect(() => {
        if (isCreated) {
            alert("Thank you for submitting the payment proof. We will verify and will give you the access to your classes shortly.")
            handleHide();
        }
    }, [isCreated])

    const handleChange = (name, e) => {
        if (name === "amount") {
            setBody({
                ...body,
                "amountPaid": {
                    "amount": e.target.value,
                    "currency": countryDetailsMap.get(country).currency
                }
            });
        } else {
            if (name === "daysToAdd") {
                setExpectedAmount(expected.get(parseInt(e.target.value)));
            }
            setBody({
                ...body,
                [name]: e.target.value
            });
        }
    }

    const handleFile = (e) => {
        setPaymentProof({
            ...paymentProof,
            file: e.target.files[0],
            name: e.target.files[0].name,
        });
    }

    const createNewPayment = () => {
        if (body.amountPaid.amount === "" || paymentProof.file === "") {
            return;
        }

        dispatch(saveFileAction(paymentProof.file));
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Step 3: Submit Payment Proof</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span className="Text5 TextLight">Select Duration</span>
                <Select className="MaterialSelect MaterialInput" variant="outlined" value={body.daysToAdd} disabled={true}
                        onChange={(e) => handleChange("daysToAdd", e)}>
                    <MenuItem value="30">30 Days</MenuItem>
                    <MenuItem value="60">60 Days</MenuItem>
                    <MenuItem value="90">90 Days</MenuItem>
                </Select>
                <div className='MediumSpace'></div>
                <span className="Text5 TextLight">Expected Amount</span>
                <div>
                    <OutlinedInput
                        className="MaterialInput"
                        style={{width: "100%"}}
                        value={expectedAmount}
                        disabled
                        startAdornment={<InputAdornment
                            position="start">{countryDetailsMap.get(country).currency}</InputAdornment>}
                    />
                </div>
                <div className='MediumSpace'></div>
                <span className="Text5 TextLight">Type Amount Paid</span>
                <div>
                    <OutlinedInput
                        className="MaterialInput"
                        style={{width: "100%"}}
                        value={body?.amountPaid?.amount}
                        onChange={(e) => handleChange("amount", e)}
                        startAdornment={<InputAdornment
                            position="start">{countryDetailsMap.get(country).currency}</InputAdornment>}
                    />
                </div>
                <div className='MediumSpace'></div>
                <span className="Text5 TextLight">Upload Proof</span>
                <div>
                    <input type="file" id="fileSelect" hidden onChange={handleFile}/>
                    <div className="FileSelector" onClick={() => document.getElementById("fileSelect").click()}>
                        <div className="LeftFile">Choose File</div>
                        <div className="RightFile">{paymentProof.name}</div>
                    </div>
                    <div className="informationalTextType">* Please ensure that the uploaded file is either Image or PDF</div>
                </div>
                <div className='MediumSpace'></div>
                <div className='MediumSpace'></div>
                {/* <TextField type="file" /> */}
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <PrimaryButton name="Previous Step" onClick={() => setTab(0)} disabled={loading}/>
                <PrimaryButton name={loading ? "Submitting..." : "Submit"} onClick={createNewPayment}
                               disabled={loading}/>
            </Modal.Footer>
        </>
    )
}

export default SubmitPayment
